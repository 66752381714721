<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-08 16:21:53
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-19 17:16:02
-->
<template>
<div>
    <el-row>
      <el-col>
        <el-button type="text"  @click="update(scope)">更新订单</el-button>
      </el-col>
    </el-row>
    <el-dialog
              title="更新订单信息"
              :visible.sync="setDialogVisible"
              :append-to-body='true'
              :modal-append-to-body='true'
              :before-close="close"
              width="50%" 
              >
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
          <el-form-item label="进厂装液时间" prop="time">
           <el-date-picker type="datetime" placeholder="选择日期时间" v-model="ruleForm.time" style="width: 100%;" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          </el-form-item>
          <el-form-item label="装车量" prop="number">
            <el-input v-model="ruleForm.number"></el-input>
          </el-form-item>
          <el-form-item label="上传磅单图片" >
            <el-upload
                      :action="uploadUrl"
                      :on-preview="handleImgPreview"
                      :on-remove="handleImgRemove"
                      list-type="picture-card"
                      :file-list="imageLists"
                      :headers="imgUploadHeaders"
                      :limit="9"
                      :class="{'demo-httpRequestImg':httpRequestImg}"
                      :on-success="handleImgUploadSuccess">
                <i class="el-icon-plus"></i>
              </el-upload>
          </el-form-item>
          <el-form-item label="卸车量" prop="unloading">
            <el-input v-model="ruleForm.unloading"></el-input>
          </el-form-item>
          <el-form-item label="上传磅单图片">
            <el-upload
                      :action="uploadUrls"
                      :on-preview="handleImgPreviews"
                      :on-remove="handleImgRemoves"
                      list-type="picture-card"
                      :file-list = 'UploadingImageLists'
                      :headers="imgUploadHeadersOne"
                      :limit="9"
                      :class="{'demo-httpRequestImg':httpRequestImgOne}"
                      :on-success="handleImgUploadSuccessOne">
                <i class="el-icon-plus"></i>
              </el-upload>
          </el-form-item>
        </el-form>
        <el-dialog
            title="图片预览"
            :visible.sync="previewDialogVisible"
            width="50%" append-to-body>
        <img width="100%" height="100%" :src="previewImgUrl" class="previewImg">
        </el-dialog>
        <span slot="footer" class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
</template>
<script>
  import { editInternalListRequest } from "network/order";

  export default {
    
    name: "OrderUpload",
    props: {
      roleId: {
        type: Number,
        default() {
          return {}
        }
      },
      orderStatus: {
        type: String,
        default() {
          return {

          }
        }
      },
      billId: {
        type: Number,
        default() {
          return {

          }
        }
      },
      scope: {
        type: Object,
        default(){
          return {

          }
        }
      },
    },
    data() {
      return {
        property: 'value',
        setDialogVisible: false,
        httpRequestImg: false,
        httpRequestImgOne: false,
        uploadUrl: this.api.imageUrl+ "/trade/purchaseorder/uploadImg",
        uploadUrls: this.api.imageUrl+ "/trade/purchaseorder/uploadImg",
        imgUploadHeaders: { // 上传图片控件的header
          Authorization: localStorage.getItem('token')
        },
        imgUploadHeadersOne: { // 上传图片控件的header
          Authorization: localStorage.getItem('token')
        },
        imageLists: [],
        UploadingImageLists: [],
        previewImgUrl: '',
        previewDialogVisible: false,
        ruleForm: {
            time: '',
            number: '',
            unloading: ''
          },
          rules: {
             number: [
              { required: true, message: '请输入装货量', trigger: 'change' }
            ],
            time: [
              {  required: true, message: '请选择日期', trigger: 'change' }
            ],
            unloading: [
              { required: true, message: '请输入卸货量', trigger: 'change' }
            ],
          }
        
              
      };
    },
    methods: {
      update(scope){
        console.log(scope,8546);
            this.setDialogVisible = true
            // let newList =this.scope.orderBill.shipmentImg==null?[]:this.scope.orderBill.shipmentImg.split(',')
            let newList =this.scope.orderBill.shipmentImg==null?[]:this.scope.orderBill.shipmentImg.split(',')
            let newUploadImage = this.scope.orderBill.unloadImg==null?[]:this.scope.orderBill.unloadImg.split(',')
            this.ruleForm.number = this.scope.orderBill.shipmentWeight
            this.ruleForm.time = this.scope.orderBill.shipmentTime
            console.log(newList,856);
            for(let i = 0;i<newList.length;i++){
              this.imageLists.push({
                name: '',
                url: this.api.imageUrl+ '/trade/images/'+newList[i]
              })
            }
            for(let x=0;x<newUploadImage.length;x++){
              this.UploadingImageLists.push({
                name: '',
                url: this.api.imageUrl+ '/trade/images/'+newUploadImage[x]
              })
            }
            this.httpRequestImg = newList.length>=9
            this.httpRequestImgOne = newUploadImage.length>=9
      },
      submitForm(formName) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              let newImage = []
              let newUploadImage = []
                for(let i = 0; i<this.imageLists.length;i++){
                  newImage.push(this.imageLists[i].url.replace(new RegExp( this.api.imageUrl+"/trade/images/",""),""))

                }
                for(let i = 0; i<this.UploadingImageLists.length;i++){
                  newUploadImage.push(this.UploadingImageLists[i].url.replace(new RegExp(this.api.imageUrl+"/trade/images/",""),""))
                }
                console.log(newImage,newUploadImage);
              editInternalListRequest({
                orderId: this.roleId,
                shipmentTime: this.ruleForm.time,
                shipmentImg: newImage.toString(),
                shipmentWeight: this.ruleForm.number,
                unloadImg: newUploadImage.toString(),
                unloadWeight: this.ruleForm.unloading,
                billId: this.billId
              }).then(res=>{
                let result = res.data;
                if (result.code !== 200) {
                  return this.alertMessage('修改失败', 'error');
                }
                this.alertMessage('修改成功', 'success');

                // 隐藏弹窗
                this.setDialogVisible=false
                this.$refs[formName].resetFields();
                this.imageLists =[]
                this.UploadingImageLists = []
                this.$emit('plan-list');
              })
              
            } else {
              
              return false;
            }
          });
        },

        resetForm(formName) {
          this.setDialogVisible=false
          this.$refs[formName].resetFields();
          this.imageLists =[]
          this.UploadingImageLists = []
        },
        close(){
            this.setDialogVisible=false
            this.imageLists=[]
            this.UploadingImageLists = []
            this.$refs.ruleForm.resetFields();
          },
        // 处理图片预览效果
        handleImgPreview(file) {
          // let reg2 = new RegExp(",");
          // let imageUrl = file.response.data.replace(reg2,'')
          // this.previewImgUrl = this.api.imageUrl+ '/trade/images/'+ imageUrl;
          this.previewImgUrl = file.url
          this.previewDialogVisible = true;
        },
        // 处理移除图片的操作
        handleImgRemove(file) {
    
          let reg2 = new RegExp(",");
          let removeUrl = file.url;
          let index = this.imageLists.findIndex(item => {
            return item.url === removeUrl;
          });
          this.imageLists.splice(index, 1);
          this.httpRequestImg = this.imageLists.length>=9
          
        },

        // 监听图片上传成功
        handleImgUploadSuccess(response) {
          let reg2 = new RegExp(",");
          let imageUrl = response.data.replace(reg2,'')
          console.log(imageUrl);
          this.imageLists.push({
            name: '',
            url: this.api.imageUrl+ '/trade/images/'+ imageUrl
          })
          this.httpRequestImg = this.imageLists.length>=9
        },


        // 处理图片预览效果
        handleImgPreviews(file) {
          // let reg2 = new RegExp(",");
          // let imageUrl = file.response.data.replace(reg2,'')
          // this.previewImgUrl = this.api.imageUrl+ '/trade/images/'+ imageUrl;
          this.previewImgUrl = file.url
          this.previewDialogVisible = true;
        },
        // 处理移除图片的操作
        handleImgRemoves(file) {
    
          let reg2 = new RegExp(",");
          let removeUrl = file.url;
          let index = this.UploadingImageLists.findIndex(item => {
            return item.url === removeUrl;
          });
          this.UploadingImageLists.splice(index, 1);
          this.httpRequestImgOne = this.UploadingImageLists.length>=9
        },

        // 监听图片上传成功
        handleImgUploadSuccessOne(response) {
          let reg2 = new RegExp(",");
          let imageUrl = response.data.replace(reg2,'')
          this.UploadingImageLists.push({
            name: '',
            url: this.api.imageUrl+ '/trade/images/'+ imageUrl
          })
          this.httpRequestImgOne = this.UploadingImageLists.length>=9

        },

    }
  }
</script>
<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
  .demo-httpRequestImg >>> .el-upload--picture-card{
    display: none !important;
  }
</style>