<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-08 16:21:53
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-05-18 17:25:19
-->
<template>
<div>
    <el-row>
      <el-col>
        <el-button type="text"  @click="updateButton">变更车辆信息</el-button>
      </el-col>
    </el-row>
  <el-dialog
            title="变更车辆信息"
            :visible.sync="setRightDialogVisible"
            :append-to-body='true'
            :modal-append-to-body='true'
            width="50%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        
        <el-form-item label="牵引车" prop="tractor">
          <el-select v-model="ruleForm.tractor" placeholder="请选择">
            <el-option v-for="item in tractorList" :key="item.tractorInformation" :label="item.tractorInformation" :value="item.tractorInformation"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="挂车" prop="trailer">
          <el-select v-model="ruleForm.trailer" placeholder="请选择">
            <el-option v-for="item in trailerList" :key="item.trailerInformation" :label="item.trailerInformation" :value="item.trailerInformation"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="司机" prop="driver">
          <el-select v-model="ruleForm.driver" placeholder="请选择">
            <el-option v-for="item in driverList" :key="item.driverInformation" :label="item.driverInformation" :value="item.driverInformation"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="押运员" prop="escort">
          <el-select v-model="ruleForm.escort" placeholder="请选择">
            <el-option v-for="item in escortList" :key="item.escortInformation" :label="item.escortInformation" :value="item.escortInformation"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    </div>
</template>
<script>
import { transportRequest } from 'network/order'
import { getTractorRequest,getTrailerRequest,getDriverRequest,getEscortRequest } from "network/order"

export default {
  name: "OrderUpdate",
  props: {
    roleId: {
      type: Number,
      default() {
        return {}
      }
    },
    trailerList:{
        type: Array,
        default(){
          return []
        }
      },
      tractorList: {
        type: Array,
        default(){
          return []
        }
      },
      driverList: {
        type: Array,
        default(){
          return []
        }
      },
      escortList: {
        type: Array,
        default(){
          return []
        }
      }
  },
  data() {
    return {
      property: 'value',
      setRightDialogVisible: false,
      ruleForm: {
          tractor: '',
          trailer: '',
          driver: '',
          escort: '',
        },
      rules: {
        tractorNumber: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        trailerNumber: [
          { required: true,message: '请选择', trigger: 'change'}
        ],
        driverInfo: [
          { required: true,message: '请选择', trigger: 'change'}
        ],
        escortInfo: [
          { required: true,message: '请选择', trigger: 'change'}
        ],
      },
      

    };
  },

    created() {
      
    },
  
  methods: {
    updateButton(){
      this.setRightDialogVisible = true
      this.ruleForm = {
          tractor: this.scope.tractorNumber==null?'':this.scope.tractorNumber,
          trailer: this.scope.trailerNumber==null?'':this.scope.trailerNumber,
          driver: this.scope.driverInfo==null?'':this.scope.driverInfo,
          escort: this.scope.escortInfo==null?'':this.scope.escortInfo,
      }
    },
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            transportRequest({
                orderId: this.roleId,
                billId: this.billId,
                driverInfo: this.ruleForm.driver,
                escortInfo: this.ruleForm.escort,
                trailerNumber: this.ruleForm.trailer,
                tractorNumber: this.ruleForm.tractor,
                distinction: 2
              }).then(res=>{
                let result = res.data;
                if (result.code !== 200) {
                  return this.alertMessage('修改失败', 'error');
                }
                this.alertMessage('修改成功', 'success');

                // 隐藏弹窗
                this.setRightDialogVisible=false
                this.$refs.ruleForm.resetFields()
                this.$emit('plan-list');
              })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.setRightDialogVisible=false
        this.$refs[formName].resetFields();
      },
  }
}
</script>
<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>