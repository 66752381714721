<template>
  <div style="height: 100%">
    <!-- 卡片视图 -->
    <el-card style="height: 100%;overflow:auto;">
      <!-- 顶部面包屑导航 -->
      <breadcrumb-nav>
        <template v-slot:firstMenu>订单管理</template>
        <template v-slot:secondMenu>外采订单</template>
      </breadcrumb-nav>
      <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
        外采订单
      </div>
      <div class="radioBox">
        <el-radio-group v-model="radio1" @change="agreeChange" fill = "#fff">
          <el-radio-button label="1" :class="radio1==1?'activeRadioButtonBox':'radioButtonBox'">待装液</el-radio-button>
          <el-radio-button label="2" :class="radio1==2?'activeRadioButtonBox':'radioButtonBox'">运输中</el-radio-button>
          <el-radio-button label="3" :class="radio1==3?'activeRadioButtonBox':'radioButtonBox'">已完成</el-radio-button>
          <el-radio-button label="4" :class="radio1==4?'activeRadioButtonBox':'radioButtonBox'">已取消</el-radio-button>
        </el-radio-group>
      </div>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="选择计划日期">
          <el-date-picker
            v-model="formInline.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="onSubmit"
            >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="购液企业">
          <el-select v-model="formInline.region1" clearable placeholder="请选择购液企业" @change="onSubmit">
            <el-option
              v-for="item in enterpriseList"
              :key="item.stationId"
              :label="item.stationName"
              :value="item.stationId"

              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="液源">
          <el-select v-model="formInline.region"  clearable placeholder="请选择液源" @change="onSubmit">
            <el-option
              v-for="item in sourceList"
              :key="item.stationId"
              :label="item.sourceName"
              :value="item.sourceName"
              >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="buttonBox" v-if="radio1!='4'">
        <el-button type="primary"  @click="ArrangePlan()">导出</el-button>
      </div>
      <!-- table表格区域 -->
      <order-table 
        :plan-list="orderList" 
        :order-status='radio1' 
        @plan-list='updateRoleList'
        :tractor-list="tractorList"
        :trailer-list="trailerList"
        :driver-list="driverList"
        :escort-list="escortList"
        />
      <div style="float: right;">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
  import BreadcrumbNav from "../common/BreadcrumbNav";
  import { getInternalListRequest,ExportRequest } from "network/order";
  import {getPurchaseListRequest} from 'network/customer';
  import { getGoodsListRequest } from 'network/goods';
  import OrderTable from './orderExternalComponents/OrderTable.vue';
  import { getTractorRequest,getTrailerRequest,getDriverRequest,getEscortRequest } from "network/order"

  export default {
    name: "List",
    components: {
      BreadcrumbNav,
      OrderTable,
    },
    data() {
      return {
         radio1: '1',
        queryInfo: {
          query: "",
          pageNum: 1,
          pageSize: 10
        },
        formInline: {
          time: '',
          region: '',
          region1: ''
        },
        orderList: [ ],
        enterpriseList: [],
        sourceList: [],
        total: 0,
        queryTime: {},
        tractorList:[],
        trailerList: [],
        driverList: [],
        escortList: [],
      }
    },
    created() {
      this.getOrderList();
      this.getEnterpriseList()
      this.getSourceList()
      this.getTractor()
      this.getTrailer()
      this.getDriver()
      this.getEscort()
    },
    methods: {
      onSubmit() {
        if(this.formInline.user){
          this.queryTime.startDate = this.formInline.user[0],
          this.queryTime.endDate = this.formInline.user[1]
        }else{
          this.queryTime ={}
        }
        this.getOrderList()
      },
      getEnterpriseList(){
        getPurchaseListRequest({
          page: {
            pageNo: '1',
            pageSize: 100000
          }
        }).then(res=>{
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取数据失败', 'error');
          }
          this.enterpriseList = result.data.list;
        })
      },
      ArrangePlan(){
        ExportRequest({
          distinction: 2,
          orderStatus: this.radio1,
        }).then(res=>{
          var content = res.headers['content-disposition'];
            var name = content && content.split(';')[1].split('filename=')[1];
            var fileName = decodeURIComponent(name)
            this.downloadCallback(res, fileName);
        })
      },

        //生成下载文件
    downloadCallback(res, fileName){
      const content = res.data;
      const blob = new Blob([content]);
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },
      getSourceList() {
        getGoodsListRequest({
          pageNumber: 1,
          pageSize: 100000,
          enterpriseId: localStorage.getItem('enterpriseId')
        }).then(res=>{
          let result = res.data;
          if(result.code !== 200) {
            return this.alertMessage('获取数据失败', 'error');
          }
          this.sourceList = result.data.list
        })
      },
      getOrderList() {
        getInternalListRequest({
          ...this.queryInfo,
          orderStatus: this.radio1,
          distinction: 2,
          ...this.queryTime,
          stationId: this.formInline.region1,
          lngSourceName: this.formInline.region,
          startDate: this.formInline.time?this.formInline.time[0]:'',
          endDate: this.formInline.time?this.formInline.time[1]:'',
          }).then(res => {
          console.log(res);
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取订单数据失败', 'error');
          }
          this.orderList = result.data.list;
          this.total = result.data.total;
        });
      },

      getTractor(){
      getTractorRequest({
        enterpriseId: localStorage.getItem('enterpriseId')
      }).then(res=>{
        let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取牵引车数据失败', 'error');
          }
          this.tractorList = result.data;
      })
    },
    getTrailer(){
      getTrailerRequest({
        enterpriseId: localStorage.getItem('enterpriseId')
      }).then(res=>{
        let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取挂车数据失败', 'error');
          }
          this.trailerList = result.data;
      })
    },
    getDriver(){
      getDriverRequest({
        enterpriseId: localStorage.getItem('enterpriseId')
      }).then(res=>{
        let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取司机数据失败', 'error');
          }
          this.driverList = result.data;
      })
    },
    getEscort(){
      getEscortRequest({
        enterpriseId: localStorage.getItem('enterpriseId')
      }).then(res=>{
        let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取押运员数据失败', 'error');
          }
          this.escortList = result.data;
      })
    },

      // 每页显示的数据条数发送变化
      handleSizeChange(newSize) {
        this.queryInfo.pageNum = 1
        this.queryInfo.pageSize = newSize;
        this.getOrderList();
      },

      // 当前页码发生变化
      handleCurrentChange(newPage) {
        this.queryInfo.pageNum = newPage;
        this.getOrderList();
      },
      updateRoleList() {
        this.getOrderList()
      },
      agreeChange(val){
        this.queryInfo.pageNum = 1,
        this.queryInfo.pageSize = 10
        this.getOrderList()
      },

    }
  }
</script>

<style scoped>

.radioBox{
  margin-top: 30px;
  margin-bottom: 15px;
}
.buttonBox{
  display: flex;
}
.el-button{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.radioButtonBox{
  background-color: var(--theme_bg_color);
  box-shadow: 0px 0 0 0 var(--theme_bg_color) !important;
  border-radius: 4px 4px 4px 4px;
}
.el-radio-button :hover{
  color: var(--theme_bg_color);
}
.activeRadioButtonBox :hover{
  color: #fff;
}
</style>
