<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-04-27 17:18:22
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2024-03-12 10:43:51
-->
<!--
 * @Description:
 * @Author:
 * @Date: 2023-02-27 15:54:08
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-22 09:29:49
-->
<template>
  <el-table highlight-current-row ref='table'  :data="planList" border  :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
    <el-table-column key="1" prop="planDate" label="计划日期" width="170px"></el-table-column>
    <el-table-column key="2" prop="orderNumber" label="订单号" width="120px"></el-table-column>
    <el-table-column key="3" prop="stationName" label="购液企业名称" width="120px"></el-table-column>
    <el-table-column key="4" prop="distributionType" label="配送方式" width="120px">
      <template slot-scope="scope">
        <span>{{scope.row.distributionType==1?"自提":scope.row.distributionType==2?"配送":""}}</span>
      </template>
    </el-table-column>
    <el-table-column key="4" prop="tractorNumber" label="车辆信息" width="120px">
      <template slot-scope="scope">
        <span>{{scope.row.tractorNumber?scope.row.tractorNumber.slice(0,-5)+'/'+scope.row.trailerNumber.slice(0,-5):"--"}}</span>
      </template>
    </el-table-column>
    <el-table-column key="5" prop="address" label="配送地" width="120px">
      <template slot-scope="scope">
        {{ scope.row.distributionType==1?scope.row.stationName:scope.row.address }}
       </template>
    </el-table-column>
    <el-table-column key="6" prop="" label="运输公司" width="120px">
      <template slot-scope="scope">
        {{ scope.row.distributionType==1?scope.row.stationName:(scope.row.transportCompany==null?scope.row.tardName:scope.row.transportCompany) }}
       </template>
    </el-table-column>
    <el-table-column key="7" prop="driverInfo" label="司机信息" width="120px">
      <template slot-scope="scope">
        <span>{{scope.row.driverInfo?scope.row.driverInfo.slice(0,-5):"--"}}</span>
      </template>
    </el-table-column>
    <el-table-column key="8" prop="escortInfo" label="押送员信息" width="120px">
      <template slot-scope="scope">
        <span>{{scope.row.escortInfo?scope.row.escortInfo.slice(0,-5):"--"}}</span>
      </template>
    </el-table-column>
    <el-table-column key="9" prop="arrivalDate" label="期望到站时间" width="120px"></el-table-column>
    <el-table-column key="10" prop="lngSourceName" label="液源" width="120px"></el-table-column>
    <el-table-column key="11" prop="factoryName" label="液厂企业名称" width="120px"></el-table-column>
    <el-table-column key="12" prop="listingPrice" label="挂牌价(元/吨)" width="120px"></el-table-column>
    <el-table-column key="13" prop="weight" label="预估总质量(吨)" width="120px"></el-table-column>
    <el-table-column key="14" prop="aggregateAmount" label="预估总金额(元)" width="120px"></el-table-column>
    <el-table-column key="15" prop="auditDate" label="审批通过时间" width="120px"></el-table-column>
    <el-table-column v-if="orderStatus==2||orderStatus==4" prop="updateDate" label="取消时间" width="120px"></el-table-column>
    <el-table-column key="16" v-if="orderStatus==2||orderStatus==3" prop="orderBill.shipmentTime" label="进厂装液时间" width="120px"></el-table-column>
    <el-table-column key="17" v-if="orderStatus==2||orderStatus==3"  prop="price" label="进厂装液价(元/吨)" width="120px"></el-table-column>
    <el-table-column key="18" v-if="orderStatus==2||orderStatus==3" prop="orderBill.shipmentWeight" label="装车量(吨)" width="120px">
      <template slot-scope="scope">
        <el-button type="text" @click="lookImage(scope.row.orderBill.shipmentImg)" >{{ scope.row.orderBill.shipmentWeight }}</el-button>
      </template>
    </el-table-column>
    <el-table-column key="19" v-if="orderStatus==3" prop="orderBill.unloadWeight" label="卸车量(吨)" width="120px">
      <template slot-scope="scope">
        <el-button type="text" @click="lookImage(scope.row.orderBill.unloadImg)" >{{ scope.row.orderBill.unloadWeight }}</el-button>
      </template>
    </el-table-column>
    <el-table-column key="20" v-if="orderStatus==3" prop="orderBill.weight" label="结算量(吨)" width="120px"></el-table-column>
    <el-table-column key="21" v-if="orderStatus==3" prop="orderBill.totalAmount" label="结算总金额(元)" width="120px"></el-table-column>
    <el-table-column key="22" v-if="orderStatus==3" prop="orderBill.reconciliationStatus" label="订单状态" width="120px">
      <template slot-scope="scope">
        <span>{{scope.row.orderBill.reconciliationStatus==1?"未对账":scope.row.orderBill.reconciliationStatus==2?"已对账":""}}</span>
      </template>
    </el-table-column>
    <el-table-column  key="23" v-if="orderStatus==4"  prop="updateUser" label="操作人" width="120px">
      <template slot-scope="scope">{{ scope.row.updateUser }}</template>
    </el-table-column>
    <el-table-column v-if="orderStatus!=4" key="24" label="操作" width="200px" fixed ="right">
      <template slot-scope="scope">
        <external-update v-if="orderStatus==1" :role-id="scope.row.orderId" :scope="scope.row" :bill-id="scope.row.orderBill?scope.row.orderBill.billId:''" @plan-list="updateRoleList"/>
        <order-update v-if="orderStatus==1&&scope.row.distributionType==2"
              :role-id="scope.row.orderId"
              :bill-id="scope.row.orderBill?scope.row.orderBill.billId:''"
              @plan-list="updateRoleList"
              :tractor-list="tractorList"
              :trailer-list="trailerList"
              :driver-list="driverList"
              :escort-list="escortList"
              :scope = "scope.row"
              />
        <order-upload v-if="scope.row.orderBill.reconciliationStatus==1 && orderStatus==2" :scope = "scope.row" :role-id="scope.row.orderId" :bill-id="scope.row.orderBill?scope.row.orderBill.billId:''" :order-status="orderStatus" @plan-list="updateRoleList"/>
        <order-save-upload v-if="scope.row.orderBill.reconciliationStatus==1 && orderStatus==3" :scope = "scope.row" :role-id="scope.row.orderId" :bill-id="scope.row.orderBill?scope.row.orderBill.billId:''" :order-status="orderStatus" @plan-list="updateRoleList"/>
        <order-cancel v-if="orderStatus==1" :row="scope.row" @plan-list="updateRoleList"/>
        <div v-if="scope.row.orderBill.reconciliationStatus==2 && orderStatus==3">--</div>
      </template>
    </el-table-column>
    <el-dialog
        title="图片预览"
        :visible.sync="previewDialogVisible"
        width="50%" append-to-body>
        <template v-if="previewDialogVisibleText == false">
          <img v-for="item in urlList"  :src="item" class="previewImg" @click="BlowUpImage(item)">
        </template>
      
      <span v-if="previewDialogVisibleText">暂无图片</span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisibleOne" width="30%" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </el-table>

</template>

<script>
import ExternalUpdate from './ExternalUpdate.vue';
import OrderSaveUpload from './OrderSaveUpload.vue';
import OrderUpdate from './OrderUpdate';
import OrderUpload from './OrderUpload.vue';
import OrderCancel from './OrderCancel.vue'
import { getTractorRequest,getTrailerRequest,getDriverRequest,getEscortRequest } from "network/order"

  export default {
    name: "PlanTable",
    props: {
      planList: {
        type: Array,
        default() {
          return []
        }
      },
      orderStatus: {
        type: String,
        default(){
          return []
        }
      },
      trailerList:{
        type: Array,
        default(){
          return []
        }
      },
      tractorList: {
        type: Array,
        default(){
          return []
        }
      },
      driverList: {
        type: Array,
        default(){
          return []
        }
      },
      escortList: {
        type: Array,
        default(){
          return []
        }
      }
    },
    data(){
      return {
        previewImgUrl: '',
        urlList: [],
        previewDialogVisible: false,
        previewDialogVisibleText: false,
        dialogVisibleOne: false,
        dialogImageUrl: ''
      }
    },
    components: {
        OrderUpdate,
        OrderUpload,
        OrderSaveUpload,
        ExternalUpdate,
        OrderCancel

    },
    created() {
    },
    watch: {
      planList(val){
        this.doLayout()
      }
    },
    methods: {
      doLayout(){
        let that = this
          this.$nextTick(() => {
            that.$refs.table.doLayout()
          })
      },
      BlowUpImage(url){
        this.dialogImageUrl=url
        this.dialogVisibleOne = true
      },
      lookImage(url){
        this.previewDialogVisible = true;
        if(url==''||url==null){
         this.previewDialogVisibleText = true
        }
        else{
          this.previewDialogVisibleText = false
          let arr = url.split(',')
          this.urlList = arr.map((item)=>this.api.imageUrl+ '/trade/images/'+item)
          // this.urlList = arr
        }
    },
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList() {
        this.$emit('plan-list');
      }
    }
  }
</script>

<style scoped>
.previewImg{
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>
