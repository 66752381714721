<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-08 16:21:53
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-19 16:59:01
-->
<template>
<div>
    <el-row>
      <el-col>
        <el-button type="text"  @click="update">更新订单</el-button>
        
      </el-col>
    </el-row>
    <el-dialog
              title="更新订单信息"
              :visible.sync="setRightDialogVisible"
              :append-to-body='true'
              :modal-append-to-body='true'
              :before-close="close"
              width="50%">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
          
          <el-form-item label="进厂装液时间" prop="time">
            <el-date-picker v-model="ruleForm.time" type="datetime" placeholder="请选择进厂装液时间" :picker-options="startDateDisabled"
                                    default-time="12:00:00" style="width: 500px;" value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
          </el-form-item>
          <el-form-item label="装车量" prop="number" style="width: 500px;">
            <el-input v-model="ruleForm.number"></el-input>
          </el-form-item>
          <el-form-item label="上传磅单图片" prop = 'images'>
            <el-upload
                      :action="uploadUrl"
                      :on-preview="handleImgPreview"
                      :on-remove="handleImgRemove"
                      list-type="picture-card"
                      :headers="imgUploadHeaders"
                      :file-list="imageLists"
                      :limit="9"
                      :class="{'demo-httpRequestImg':httpRequestImg}"
                      :on-success="handleImgUploadSuccess">
                <i class="el-icon-plus"></i>
              </el-upload>
          </el-form-item>
        </el-form>
        <el-dialog
            title="图片预览"
            :visible.sync="previewDialogVisible"
            width="50%" append-to-body>
        <img width="100%" height="100%" :src="previewImgUrl" class="previewImg">
        </el-dialog>
        <span slot="footer" class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
</template>
<script>
  import { updateOrderRequest } from "network/order";

  export default {
    
    name: "ExternalUpdate",
    props: {
      roleId: {
        type: Number,
        default() {
          return {}
        }
      },
      scope: {
        type: Object,
        default(){
          return {

          }
        }
      },
      // orderStatus: {
      //   type: String,
      //   default() {
      //     return {

      //     }
      //   }
      // },
      billId: {
        type: Number,
        default() {
          return {

          }
        }
      }
    },
    data() {
      return {
        property: 'value',
        setRightDialogVisible: false,
        httpRequestImg: false,
        uploadUrl: this.api.imageUrl+ "/trade/purchaseorder/uploadImg",
        imgUploadHeaders: { // 上传图片控件的header
          Authorization: localStorage.getItem('token')
        },
        imageLists: [],
        previewImgUrl: '',
        previewDialogVisible: false,
        startDateDisabled:{},
        ruleForm: {
            time: '',
            number: ''
            
          },
          rules: {
            number: [
              { required: true, message: '请输入装货量', trigger: 'change' }
            ],
            time: [
            { required: true, message: '请选择日期', trigger: 'change' }
          ],
          }
        
              
      };
    },
    methods: {
      update(){
        if(!this.scope.tractorNumber||!this.scope.driverInfo||!this.scope.escortInfo){
          return this.alertMessage('请先完善车辆信息', 'error');
        }else{
            this.setRightDialogVisible = true
            let newList =this.scope.orderBill.shipmentImg==null?[]:this.scope.orderBill.shipmentImg.split(',')
            this.ruleForm.number = this.scope.orderBill.shipmentWeight
            this.ruleForm.time = this.scope.orderBill.shipmentTime
            for(let i = 0;i<newList.length;i++){
              this.imageLists.push({
                name: '',
                url: this.api.imageUrl+ '/trade/images/'+newList[i]
              })
            }
            this.httpRequestImg = newList.length>=9
        }
       
      },
      submitForm(formName) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              console.log(this.imageLists.toString());
              let newImage = []
                for(let i = 0; i<this.imageLists.length;i++){
                  // newImage.push(this.imageLists[i].url.replace(new RegExp("https://test.sysongy.mobi:7000/trade/images/",""),""))
                  newImage.push(this.imageLists[i].url.replace(new RegExp(this.api.imageUrl+"/trade/images/",""),""))

                }
                console.log(newImage);
              updateOrderRequest({
                orderId: this.roleId,
                orderBill: {
                  shipmentImg: newImage.toString(),
                  shipmentWeight: this.ruleForm.number,
                  shipmentTime: this.ruleForm.time,
                  billId: this.billId
                }
              }).then(res=>{
                let result = res.data;
                if (result.code !== 200) {
                  return this.alertMessage('修改失败', 'error');
                }
                this.alertMessage('修改成功', 'success');

                // 隐藏弹窗
                this.setRightDialogVisible=false
                this.$refs.ruleForm.resetFields();
                this.imageLists = []
                this.$emit('plan-list');
              })
              
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        resetForm(formName) {
          this.setRightDialogVisible=false
          this.imageLists = []
          this.$refs[formName].resetFields();
        },

        close(){
            this.setRightDialogVisible=false
            this.imageLists = []
            this.$refs.ruleForm.resetFields();
          },

        // 处理图片预览效果
        handleImgPreview(file) {
          console.log(file);
          let reg2 = new RegExp(",");
          // let imageUrl = file.response.data.replace(reg2,'')
          // this.previewImgUrl = this.api.imageUrl+ '/trade/images/'+ imageUrl;
          this.previewImgUrl = file.url
          this.previewDialogVisible = true;
        },
        // 处理移除图片的操作
        handleImgRemove(file) {
  
          // let reg2 = new RegExp(",");
           // let reg2 = new RegExp(",");
           let removeUrl = file.url;
          let index = this.imageLists.findIndex(item => {
            return item.url === removeUrl;
          });
          this.imageLists.splice(index, 1);
          this.httpRequestImg = this.imageLists.length>=9
        },

        // 监听图片上传成功
        handleImgUploadSuccess(response) {
          let reg2 = new RegExp(",");
          let imageUrl = response.data.replace(reg2,'')
          this.imageLists.push({
            name: '',
            url: this.api.imageUrl+ '/trade/images/'+ imageUrl
          })
          this.httpRequestImg = this.imageLists.length>=9
        },

    }
  }
</script>
<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
  .demo-httpRequestImg >>> .el-upload--picture-card{
    display: none !important;
  }
</style>